import React from 'react'
import Layout from '@components/layout/layout'
import SEO from '@components/shared/seo'
import Container from '@components/shared/container'
import seoData from '@seo-data/contact'

export default class ContactPage extends React.Component {
    state = {
      name: '',
      mail: '',
      message: '',
      nameIsFocused: false,
      emailIsFocused: false,
      messageIsFocused: false,
      nameIsValid: false,
      mailIsValid: false,
      messageIsValid: false
    }

    handleInputChange = event => {
      const value = event.target.value
      const name = event.target.id
      this.setState({ [name]: value })
    }

    handleSubmit = event => {
      event.preventDefault()

      // alert('@Todo : requête endpoint POST  + message de confirmation')
      this.setState({
        name: '',
        mail: '',
        message: '',
        nameIsFocused: false,
        mailIsFocused: false,
        messageIsFocused: false,

      })
    }

    handleFocus = event => {
      const target = event.target.id + 'IsFocused'
      let newState = event.target.value.length > 0 || !this.state[target]
      this.setState({ [target]: newState })
    }

    render () {

      return (
        <Layout>
          <SEO data={seoData} />
          <Container>
            <div className="contact-page page-padding">
              <section className="contact-infos">
                <h3 className="contact-infos__title">
                  Une idée en tête ? Un besoin ?<br />Un projet ?<br />Nous sommes là pour vous écouter et vous répondre
                </h3>
              </section>
              <section className="form">
                <div className="form__title">
                  <h2 >Nous écrire</h2>
                  <div className="vertical-line" aria-hidden="true"></div>
                </div>
                <div className="form__infos">
                  <address className="address">
                    <p className="address__subtitle">E-mail</p>
                    <a href="mailto:contact@acolad.fr">contact@acolad.fr</a>
                  </address>
                  <div className="address tel">
                    <p className="address__subtitle">Téléphone</p>
                    <a href="tel:+33613789134">06 13 78 91 34</a>
                  </div>
                </div>
                {/*<form onSubmit={this.handleSubmit} noValidate>*/}
                  {/*<div className="form__fields">*/}
                    {/*<div className={this.state.nameIsFocused ? 'field field--label-up' : 'field'}>*/}
                      {/*<label htmlFor="name">Votre nom</label>*/}
                      {/*<input*/}
                        {/*required*/}
                        {/*id="name"*/}
                        {/*type="text"*/}
                        {/*value={this.state.name}*/}
                        {/*onChange={this.handleInputChange}*/}
                        {/*onFocus={this.handleFocus}*/}
                        {/*onBlur={this.handleFocus}*/}
                      {/*/>*/}
                    {/*</div>*/}
                    {/*<div className={this.state.mailIsFocused ? 'field field--label-up' : 'field'}>*/}
                      {/*<label htmlFor="mail">Votre adresse email</label>*/}
                      {/*<input*/}
                        {/*type="email"*/}
                        {/*id="mail"*/}
                        {/*value={this.state.mail}*/}
                        {/*onChange={this.handleInputChange}*/}
                        {/*onFocus={this.handleFocus}*/}
                        {/*onBlur={this.handleFocus}*/}
                      {/*/>*/}
                    {/*</div>*/}
                    {/*<div className={this.state.messageIsFocused ? 'field field--label-up field--textarea' : 'field field--textarea'}>*/}
                      {/*<label htmlFor="message">Votre message</label>*/}
                      {/*<textarea rows="10" cols="33"*/}
                        {/*id="message"*/}
                        {/*value={this.state.message}*/}
                        {/*onChange={this.handleInputChange}*/}
                        {/*onFocus={this.handleFocus}*/}
                        {/*onBlur={this.handleFocus}*/}
                      {/*/>*/}
                    {/*</div>*/}
                  {/*</div>*/}
                  {/*<button className="form__btn" type="submit">Envoyer</button>*/}
                {/*</form>*/}
              </section>
            </div>
          </Container>
        </Layout>
      )
    }
}
