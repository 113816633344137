import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

class SEO extends React.Component {
  render () {
    const baseUrl = 'https://www.acolad.fr/'
    const openGraph = this.props.data.openGraph
    const microdata = this.props.data.microdata
    const title = openGraph.id === 'home' ? `${ 'Acolad développement' } | %s` : `%s | ${ 'Acolad développement' }`
    let meta = [
      {
        name: `viewport`,
        content: `width=device-width, initial-scale=1.0, shrink-to-fit=no`,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:site_name`,
        content: `Acolad développement`,
      },
      {
        property: `og:locale`,
        content: `fr_FR`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        property: `og:image`,
        content: baseUrl + 'static/images/logo.png',
      },
      {
        property: `og:image:type`,
        content: `image/png`,
      },
      {
        property: `og:image:width`,
        content: `1480`,
      },
      {
        property: `og:image:height`,
        content: `1480`,
      },
      {
        name: `twitter:image`,
        content: baseUrl + 'static/images/logo.png',
      }
    ];

    if(openGraph.id) {
      meta.push({
        property: `og:url`,
        content: openGraph.id !== 'home' ?  baseUrl + openGraph.id :  baseUrl,
      });
      meta.push({
        name: `twitter:url`,
        content: openGraph.id !== 'home' ? baseUrl + openGraph.id :  baseUrl,
      });
    }

    if(openGraph.title) {
      meta.push({
        property: `og:title`,
        content: openGraph.title,
      });
      meta.push({
        name: `twitter:title`,
        content: openGraph.title,
      });
    }

    if(openGraph.description) {
      meta.push({
        name: `description`,
        content: openGraph.description,
      });
      meta.push({
        property: `og:description`,
        content: openGraph.description,
      });
      meta.push({
        name: `twitter:description`,
        content: openGraph.description,
      });
    }

    return (
      <Helmet
        htmlAttributes={{ lang: `fr` }}
        title={openGraph.title}
        titleTemplate={title}
        meta={meta}
      >
        <script type="application/ld+json">
          {JSON.stringify(microdata)}
        </script>
      </Helmet>
    )
  }
}

SEO.propTypes = {
  data: PropTypes.object,
}

SEO.defaultProps = {
  data: {}
}

export default SEO
