import logo from '@images/logo.png'

const seoData = {
  "openGraph": {
    "id": "contact",
    "title": "Contactez-nous",
    "description": "Une question ? Quelle que soit votre demande, contactez-nous !",
  },
  "microdata": {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Contact",
    "mainEntity": {
      "@type": "Organization",
      "name": "Acolad Développement",
      "url": "https://www.acolad.fr",
      "logo": "https://www.acolad.fr/static/images/logo.png",
      "foundingDate": "2014",
      "founders": [
        {
          "@type": "Person",
          "name": "Raphael Apard"
        }
      ],
      "employee": [
        {
          "@type": "Person",
          "@id": "https://www.acolad.fr/equipe#raph",
          "name": "Raphael Apard",
          "jobTitle": "Developer"
        },
        {
          "@type": "Person",
          "@id": "https://www.acolad.fr/equipe#celia",
          "name": "Célia Chazel",
          "jobTitle": "Developer"
        },
      ],
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Cahors",
        "addressRegion": "Occitanie",
        "postalCode": "46000",
        "addressCountry": "France"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer service",
        "telephone": "[+33613789134]",
        "email": "contact@acolad.fr"
      },
      "sameAs": [
        "https://www.facebook.com/acoladev",
        "https://www.linkedin.com/company/acolad-développement",
      ]
    },
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": "https://www.acolad.fr/",
          "name": "Accueil"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": "https://www.acolad.fr/contact",
          "name": "Contactez-nous"
        }
      ]
    }
  }
}

export default seoData
